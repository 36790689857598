import TopServiceCard from "../../components/topServiceCard";
import visionImg from "../../assets/images/vision.jpg";
import missionImg from "../../assets/images/mission.jpg";

const AboutUs = () => {
  return (
    <div className="text-black dark:text-white py-6">
      <div className="mb-24">
        <div className="grid grid-cols-1 gap-8 items-center mb-8">
          <div className="w-[100%] md:w-[80%] justify-self-center">
            <p className="text-2xl font-medium text-[#1DA1F2] text-center md:px-20 crimson-font">
              We are a beacon of technological excellence, setting new standards
              for the industry.
            </p>
            <p className="leading-loose mt-4 text-center text-sm">
              At Cloudvantage, our vision is to be the foremost provider of
              affordable cutting-edge cloud solutions that transform the way
              businesses and individuals connect, collaborate, and innovate.
            </p>
          </div>
          <div>
            <img
              src={visionImg}
              alt=""
              className="rounded-md h-[300px] md:h-[450px] w-full object-cover"
            />
          </div>
        </div>

        <p className="text-xl font-medium text-center lg:text-start crimson-font">
          Our Vision Includes:
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-center lg:text-left mt-2">
          <TopServiceCard
            icon={"hidden"}
            heading={"Leading Digital Transformation:"}
            body={
              "We aim to lead the charge in enabling organizations to seamlessly transition to the cloud, fostering digital transformation and ensuring their future success"
            }
          />
          <TopServiceCard
            icon={"hidden"}
            heading={"Shaping The Future:"}
            body={
              "We seek to actively shape the future of cloud technology by pioneering advancements that drive innovation and open up new possibilities for our clients."
            }
          />
          <TopServiceCard
            icon={"hidden"}
            heading={"Empowering Businesses:"}
            body={
              "We envision a world where businesses of all sizes can harness the full potential of cloud infrastructure to thrive and compete in the global marketplace."
            }
          />
          <TopServiceCard
            icon={"hidden"}
            heading={"Global Reach:"}
            body={
              "We aspire to expand our global footprint, connecting businesses and individuals across borders, making the cloud accessible to all."
            }
          />
          <TopServiceCard
            icon={"hidden"}
            heading={"Environmental Stewardship:"}
            body={
              "We are committed to reducing the environmental footprint of our operations and promoting sustainable practices within the cloud industry."
            }
          />
          <TopServiceCard
            icon={"hidden"}
            heading={"Industry Leadership:"}
            body={
              "We strive to be recognized as a thought leader, setting the benchmark for excellence in cloud services, and influencing the direction of the industry."
            }
          />
          <TopServiceCard
            icon={"hidden"}
            heading={"Customer-Centric Innovation:"}
            body={
              "We focus on continuously listening to our customers, understanding their evolving needs, and developing innovative solutions that exceed their expectations."
            }
          />
        </div>

        <p className="opacity-80 text-center mt-20 italic text-2xl crimson-font leading-relaxed">
          "Our <span className="font-semibold text-[#1DA1F2]">vision</span> is
          rooted in the belief that the cloud is not just a technology but a
          catalyst for progress, enabling businesses and individuals to achieve
          their goals and dreams. Together, we will create a brighter, more
          connected, and digitally empowered future for all."
        </p>
      </div>

      {/* Mission */}
      <div className="mt-24">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center mb-8">
          <div>
            <p className="text-2xl font-semibold text-[#1DA1F2] crimson-font text-center lg:text-start">
              Our Mission
            </p>
            <p className="leading-relaxed mt-4 text-sm text-center lg:text-start ">
              "At Cloudvantage, our mission is to empower small and medium scale
              businesses and individuals by providing robust and reliable cloud
              infrastructure solutions. We are committed to delivering the
              highest quality cloud services that enable our clients to thrive
              in this ever evolving digital age."
            </p>
          </div>
          <div>
            <img src={missionImg} alt="" className="rounded-md" />
          </div>
        </div>

        <p className="text-xl font-semibold text-center lg:text-start crimson-font">
          We Strive To:
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-center lg:text-left mt-2">
          <TopServiceCard
            icon={"hidden"}
            heading={"Foster Innovation:"}
            body={
              "We aim to be at the forefront of technological advancements, constantly innovating and evolving our infrastructure to meet the changing needs of our customers."
            }
          />
          <TopServiceCard
            icon={"hidden"}
            heading={"Enable Growth:"}
            body={
              "We are dedicated to helping businesses grow and scale efficiently by providing a scalable, secure, and flexible cloud infrastructure."
            }
          />
          <TopServiceCard
            icon={"hidden"}
            heading={"Ensure Reliability:"}
            body={
              "We pledge to maintain the highest standards of reliability and uptime, ensuring that our clients can depend on our services day in and day out"
            }
          />
          <TopServiceCard
            icon={"hidden"}
            heading={"Global Reach:"}
            body={
              "We aspire to expand our global footprint, connecting businesses and individuals across borders, making the cloud accessible to all."
            }
          />
          <TopServiceCard
            icon={"hidden"}
            heading={"Enhance Security:"}
            body={
              "We prioritize the security and privacy of our clients' data, employing state-of-the-art security measures to safeguard their information."
            }
          />
          <TopServiceCard
            icon={"hidden"}
            heading={"Simplify Complexity:"}
            body={
              "We are committed to simplifying the complexities of cloud infrastructure management, making it accessible and understandable for all."
            }
          />
          <TopServiceCard
            icon={"hidden"}
            heading={"Provide Exceptional Support:"}
            body={
              "We offer unparalleled customer support to assist our clients in making the most of their cloud infrastructure investment"
            }
          />
          <TopServiceCard
            icon={"hidden"}
            heading={"Foster Sustainability:"}
            body={
              "We are mindful of our environmental impact and actively work to minimize it through energy-efficient infrastructure and eco-friendly practices."
            }
          />
        </div>
        <p className="opacity-80 text-center mt-20 italic text-2xl crimson-font leading-relaxed">
          "By pursuing these principles, we aim to be the trusted partner that
          helps businesses and individuals harness the full potential of the
          cloud, making their digital aspirations a reality."
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
