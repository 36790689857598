import { useState } from "react";
import Select from "../../../components/select";
import { useDispatch } from "react-redux";
import arrow from "../../../assets/svg-icons/blueArrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { usePaymentHistoryList } from "../layout/resuableEffect";

const PaymentHistory = () => {
  document.title = "Payment history - CloudVantage";
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const options = [
    { value: "all-payments", label: "All payments" },
    { value: "mode-of-payment", label: "Mode of Payment" },
    { value: "payment-status", label: "Payment Status" },
  ];

    const [searchText, setSearchText] = useState("");
  
    const handleSearchChange = (event) => {
      setSearchText(event.target.value);
      setCurrentPage(1);
    };


      const paymentHistoryList = usePaymentHistoryList();
      const paymentHistoryListMap = Array.isArray(paymentHistoryList)
      ? paymentHistoryList.map((payment, index) => ({
        sn: index + 1,
        modeOfPayment: `${payment?.mode}`,
        amountPaid: `${payment?.amount}`,
        paymentStatus: payment?.payment_status,
        date: payment?.created_on
      }))
     : [];


  
    const [currentPage, setCurrentPage] = useState(1);
    const entriesPerPage = 5;

    const filteredData = paymentHistoryListMap?.filter((row) =>
      row?.modeOfPayment?.toLowerCase().includes(searchText.toLowerCase()) ||
      row?.paymentStatus?.toLowerCase().includes(searchText.toLowerCase()),
    );

    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = filteredData.slice(
      indexOfFirstEntry,
      indexOfLastEntry,
    );
    const totalPages = Math.ceil(filteredData.length / entriesPerPage);
  
    const handlePreviousPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const handleNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
      }
    };
  

  return (
    <div className="min-h-screen px-4 md:px-10 pt-6 pb-10 dark:text-white">
      <p className="crimson-font text-lg font-medium text-primaryBlue">Payment history</p>


      {paymentHistoryListMap?.length > 0 ?
          <div>
            <div>
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4 items-end my-10">
              <div className="md:col-span-3">
                <Select label="Filter payments" name="payments" options={options} />
              </div>
              <div className="md:col-span-4">
                <input
                  type="search"
                  name=""
                  value={searchText}
                  onChange={handleSearchChange}
                  placeholder="Search by mode of payment, status..."
                  className="bg-white text-black w-full p-4 text-sm rounded-md border border-[#c4c4c460] active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs dark:bg-slate-800 dark:text-white"
                />
              </div>
            </div>
            <div className="overflow-x-auto rounded-md border dark:border-[#c4c4c432]">
              <table className="w-full text-sm text-left overflow-hidden">
                <thead className="bg-white dark:bg-slate-800 px-3 md:px-4 border text-gray-400 dark:border-[#c4c4c432] border-b text-sm font-semibold">
                  <tr>
                    <th scope="col" className="px-6 py-4 whitespace-nowrap">
                      S/N
                    </th>
                    <th scope="col" className="px-6 py-4 whitespace-nowrap">
                      Mode of Payment
                    </th>
                    <th scope="col" className="px-6 py-4 whitespace-nowrap">
                      Amount Paid
                    </th>
                    <th scope="col" className="px-6 py-4 whitespace-nowrap">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-4 whitespace-nowrap">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-slate-800 px-3 md:px-4 border rounded-b-md dark:border-[#c4c4c432] border-t-2 text-sm">
                  {currentEntries.map((row, index) => (
                    <tr
                      key={index}
                      className="dark:hover:bg-[#ffffff05] hover:bg-brandBlue/25 whitespace-nowrap"
                    >
                      <td className="text-start py-4 px-6">{row?.sn}.</td>
                      <td className="text-start py-4 px-6">{row?.modeOfPayment}</td>
                      <td className="text-start py-4 px-6">{row?.amountPaid}</td>
                      <td className="text-start py-4 px-6">{row?.paymentStatus}</td>
                      <td className="text-start py-4 px-6">{row?.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="grid justify-center gap-4 md:flex md:justify-between items-center mt-4">
              <p className="text-gray-400 text-sm">
                Showing{" "}
                <span className="font-medium text-black dark:text-white">
                  {indexOfFirstEntry + 1}
                </span>{" "}
                to{" "}
                <span className="font-medium text-black dark:text-white">
                  {Math.min(indexOfLastEntry, paymentHistoryListMap?.length)}
                </span>{" "}
                of{" "}
                <span className="font-medium text-black dark:text-white">
                  {paymentHistoryListMap?.length}
                </span>{" "}
                results
              </p>
              <div className=" flex gap-3">
                <button
                  className={`bg-white dark:bg-primaryBlue border dark:border-none rounded-md py-3 px-5 text-sm ${currentPage === 1 ? "opacity-50" : "opacity-100"}`}
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button
                  className={`bg-white dark:bg-primaryBlue border dark:border-none rounded-md py-3 px-5 text-sm ${currentPage === totalPages ? "opacity-50" : "opacity-100"}`}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          </div>

          :

          <>
          <div className="md:px-52 text-center grid text-gray-500 mt-32">
            <div>It looks like you don't have a payment history yet. Once you make a payment for a service, your payment details will appear here.</div>
            <Link to={"/client-area/vps"} className="border border-[#c4c4c450] dark:border-[#c4c4c416] rounded-md p-3 flex items-center justify-self-center mt-8 w-fit text-xs">
              <span>View VPS plans</span>
              <span className="ps-2">
                <img src={arrow} alt="" />
              </span>
            </Link>
          </div>
        
        </>
    
      }
    </div>
  );
};

export default PaymentHistory;
