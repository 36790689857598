import { useState } from "react";
import Select from "../../../components/select";
import arrow from "../../../assets/svg-icons/blueArrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useInvoiceList } from "../layout/resuableEffect";

const Billing = () => {
  document.title = "Billing - Cloudvantage";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");



  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    setCurrentPage(1);
  };

  const options = [
    { value: "all-invoices", label: "All invoices" },
    { value: "resource", label: "Resource" },
    { value: "resource-name", label: "Resource Name", },
    { value: "status", label: "Status", },
  ];

  const invoiceList = useInvoiceList();


  const invoiceListMap = Array.isArray(invoiceList)
  ? invoiceList.map((invoice, index) => ({
    invoiceNumber: invoice?.display_id,
    resourceName: invoice?.resource?.name,
    resource: invoice?.resource?.resource,
    creationDate: invoice?.resource?.created_on || "Not Provided yet.",
    dueDate: invoice?.due_date,
    amount: `${invoice?.amount}`,
    status: invoice?.status === "unpaid" ? "Make Payment" : "Paid"
  }))
 : [];


  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5;

  const filteredData = invoiceListMap?.filter((row) =>
    row.resourceName.toLowerCase().includes(searchText.toLowerCase()) ||
    row.resource.toLowerCase().includes(searchText.toLowerCase()) ||
    row.status.toLowerCase().includes(searchText.toLowerCase()),

  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(
    indexOfFirstEntry,
    indexOfLastEntry,
  );
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="min-h-screen px-4 md:px-10 pt-6 pb-10 dark:text-white">
      <p className="crimson-font text-xl font-medium text-primaryBlue">
        Manage your billing
      </p>


      {invoiceListMap?.length > 0 ?
        <div>
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 items-end my-10">
            <div className="md:col-span-3">
              <Select label="Filter Invoices" name="invoices" options={options} />
            </div>
            <div className="md:col-span-4">
              <input
                type="search"
                name=""
                value={searchText}
                onChange={handleSearchChange}
                placeholder="Search by resource, name or status..."
                className="bg-white text-black w-full p-4 text-sm rounded-md border border-[#c4c4c460] active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs dark:bg-slate-800 dark:text-white"
              />
            </div>
          </div>
          <div className="overflow-x-auto rounded-md border dark:border-[#c4c4c432]">
            <table className="w-full text-sm text-left overflow-hidden">
              <thead className="bg-white dark:bg-slate-800 px-3 md:px-4 border text-gray-400 dark:border-[#c4c4c432] border-b text-sm font-semibold">
                <tr>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Invoice no.
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Resource Name
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Resource
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Creation date
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Due date
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-slate-800 px-3 md:px-4 border rounded-b-md dark:border-[#c4c4c432] border-t-2 text-sm">
                {currentEntries.map((row, index) => (
                  <tr
                    key={index}
                    className="dark:hover:bg-[#ffffff05] hover:bg-brandBlue/25 whitespace-nowrap"
                  >
                    <td className="text-start py-4 px-6">{row.invoiceNumber}</td>
                    <td className="text-start py-4 px-6">{row.resourceName}</td>
                    <td className="text-start py-4 px-6">{row.resource}</td>
                    <td className="text-start py-4 px-6">{row.creationDate}</td>
                    <td className="text-start py-4 px-6">{row.dueDate}</td>
                    <td className="text-start py-4 px-6">{row.amount}</td>
                    <td className="text-start py-4 px-6">
                      {row.status === "Make Payment" ?               
                      <button
                          className="text-white p-3 text-xs rounded bg-primaryBlue md:w-full w-fit"
                          onClick={() => {}}
                        >
                          Make Payment
                        </button>
                        :
                        <>
                        {row.status}
                        </>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="grid justify-center gap-4 md:flex md:justify-between items-center mt-4">
            <p className="text-gray-400 text-sm">
              Showing{" "}
              <span className="font-medium text-black dark:text-white">
                {indexOfFirstEntry + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium text-black dark:text-white">
                {Math.min(indexOfLastEntry, invoiceListMap?.length)}
              </span>{" "}
              of{" "}
              <span className="font-medium text-black dark:text-white">
                {invoiceListMap?.length}
              </span>{" "}
              results
            </p>
            <div className=" flex gap-3">
              <button
                className={`bg-white dark:bg-primaryBlue border dark:border-none rounded-md py-3 px-5 text-sm ${currentPage === 1 ? "opacity-50" : "opacity-100"}`}
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                className={`bg-white dark:bg-primaryBlue border dark:border-none rounded-md py-3 px-5 text-sm ${currentPage === totalPages ? "opacity-50" : "opacity-100"}`}
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>

        :

        <>
          <div className="md:px-52 text-center grid text-gray-500 mt-32">
            <div>It looks like you don't have a billing history yet. Once you make a payment for a service, your billing details will appear here.</div>
            <Link to={"/client-area/vps"} className="border border-[#c4c4c450] dark:border-[#c4c4c416] rounded-md p-3 flex items-center justify-self-center mt-8 w-fit text-xs">
              <span>View VPS plans</span>
              <span className="ps-2">
                <img src={arrow} alt="" />
              </span>
            </Link>
          </div>
        
        </>
      
      }
      

    </div>
  );
};

export default Billing;
