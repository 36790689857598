import { useSelector } from "react-redux";
import Flavours from "../../../components/flavours";
import React, { useState, useContext } from "react";
import { CartContext } from "../../../components/cartContext";
import { useListDews } from "../layout/resuableEffect";
import Spinner from "../../../components/spinner";
import addServerIcon from "../../../assets/svg-icons/addServericon.svg";
import ram from "../../../assets/svg-icons/ram.svg";
import disk from "../../../assets/svg-icons/disk.svg";
import whiteDisk from "../../../assets/svg-icons/whiteDisk.svg";
import whiteRAM from "../../../assets/svg-icons/whiteRAM.svg";
import whiteCPU from "../../../assets/svg-icons/whiteCPU.svg";
import cpu from "../../../assets/svg-icons/cpu-icon.svg";
import Select from "../../../components/select";
import Input from "../../../components/input";
import closeIcon from "../../../assets/svg-icons/closeBrand.svg";

const BuyVPS = () => {
  document.title = "Buy VPS - CloudVantage";
  const currency = useSelector((state) => state.user.currency);
  const { setCartNumber } = useContext(CartContext);
  const vpsList = useListDews();
  const vpsMapList = vpsList.results || [];
  const [currentCartItemId, setCurrentCartItemId] = useState(null);

  const formatName = (name) => {
    return name
      .split(".")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };
  const addToCart = (flavor) => {
    const price = flavor.price;
    sessionStorage.setItem("cart", JSON.stringify([flavor]));
    setCartNumber(price);
    setCurrentCartItemId(flavor.productId);
  };

  const removeFromCart = () => {
    sessionStorage.removeItem("cart");
    setCartNumber(0);
    setCurrentCartItemId(null);
  };

  const [customizeModal, setCustomizeModal] = useState(false);

  const toggleCustomizeModal = () => {
    setCustomizeModal(!customizeModal);
  }

  const VCPUOptions = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 4, label: "4" },
    { value: 6, label: "6" },
    { value: 8, label: "8" },
    { value: 12, label: "12" },
    { value: 24, label: "24" },
    { value: 48, label: "48" },
    { value: 64, label: "64" },
    { value: 128, label: "128" },
  ];

  const RAMOptions = [
    { value: "512mb", label: "512MB" },
    { value: "1Gb", label: "1GB" },
    { value: "2Gb", label: "2GB" },
    { value: "4Gb", label: "4GB" },
    { value: "6Gb", label: "6GB" },
    { value: "8Gb", label: "8GB" },
    { value: "12Gb", label: "12GB" },
    { value: "24Gb", label: "24GB" },
    { value: "48Gb", label: "48GB" },
    { value: "64Gb", label: "64GB" },
    { value: "128Gb", label: "128GB" },
  ];
  
  

  return (
    <div className="pb-6 min-h-screen relative grid">
      <Spinner loading={useSelector((state) => state.user).loading} />
      <div>
        <div>
          <p className="dark:text-white text-sm font-medium">
            Create a Virtual Instance:
          </p>
          <p className="text-black/40 dark:text-white/40 mb-4 text-xs">
            Select dew
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {vpsMapList.length > 0 &&
            vpsMapList.map((val, key) => {
              const price =
                currency === "NGN"
                  ? val.naira_price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "NGN",
                    })
                  : val.dollar_price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    });

              return (
                <React.Fragment key={key}>
                  <Flavours
                    productId={val.id}
                    name={formatName(val.name)}
                    price={price}
                    keys={key}
                    nairaPrice={val.naira_price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "NGN",
                    })}
                    dollarPrice={val.dollar_price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                    vcpu={val.vcpus}
                    ramSize={val.ram}
                    diskSize={val.disk}
                    addToCart={addToCart}
                    removeFromCart={removeFromCart}
                    isInCart={currentCartItemId === val.id}
                    flavorId={val.flavor_id}
                  />
                  {/* Customize flavour */}
                  {key === vpsMapList.length - 1 && (
                    <div className="border-[3px] border-dashed border-[#c4c4c450] dark:border-[#c4c4c416] dark:text-white rounded p-4 hover:bg-white dark:hover:bg-[#ffffff08] flex items-center justify-center cursor-pointer" onClick={toggleCustomizeModal}>
                      <div className="grid gap-4 text-sm text-center">
                        <img
                          src={addServerIcon}
                          alt="icon-animated"
                          className="h-10 justify-self-center"
                        />
                        <span>Customize your flavour!</span>
                        <div className="flex gap-3 justify-center">
                          <div className="">
                            <img
                              src={cpu}
                              alt=""
                              className="h-5 md:h-[18px] dark:hidden"
                            />
                            <img
                              src={whiteCPU}
                              alt=""
                              className="h-5 md:h-[18px] hidden dark:block"
                            />
                          </div>
                          <div className="">
                            <img
                              src={disk}
                              alt=""
                              className="h-5 md:h-[18px] dark:hidden"
                            />
                            <img
                              src={whiteDisk}
                              alt=""
                              className="h-5 md:h-[18px] hidden dark:block"
                            />
                          </div>
                          <div className="">
                            <img
                              src={ram}
                              alt=""
                              className="h-5 md:h-[18px] dark:hidden"
                            />
                            <img
                              src={whiteRAM}
                              alt=""
                              className="h-5 md:h-[18px] hidden dark:block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
        </div>
      </div>

      {customizeModal &&
      <div className="fixed w-full bg-black/60 top-0 left-0 z-50 h-screen">
        <div className="flex h-full items-center justify-center p-4">
          <div className="bg-white dark:bg-[#141D26] dark:text-white w-full md:w-1/3 rounded max-h-[80%] md:max-h-[90%]">
            <div className="px-6 md:px-10 py-6 border-b dark:border-b-[#c4c4c432] flex justify-between items-center">
              <span className="text-primaryBlue font-medium">
                Customize your flavour
              </span>
              <span onClick={toggleCustomizeModal} className="cursor-pointer">
                <img src={closeIcon} alt="Close" className="h-5"/>
              </span>
            </div>
            <div className="py-10 grid gap-4">
              <div className="px-6 md:px-10 grid gap-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <Select label={"VCPUs"} name="protocol" options={VCPUOptions} />
                  <Select label={"RAM"} name="protocol" options={RAMOptions} />
                </div>
                <Input label={"Storage"} placeholder={"Enter value in MB"} />
              </div>
              <div className="px-6 md:px-10 grid gap-2">
                <div className="">
                  <div className="text-xs">Estimated cost:</div>
                  <div className="font-semibold crimson-font text-primaryBlue text-lg">NGN 20,000</div>
                </div>
                <button type="submit" className="text-sm md:text-xs rounded text-white bg-[#1DA1F2] px-8 py-4 cursor-pointer w-fit">
                    Configure
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      }

      {/* <div>
        <p className="dark:text-white mb-4">Why choose our VPS:</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="bg-brandGreen rounded-md px-6 py-10">
            <img src={performance} alt="" class="h-5" />
            <p className="leading-snug crimson-font font-bold mb-3 mt-4 w-full">
              {" "}
              Performance{" "}
            </p>
            <p className="text-sm leading-6">
              {" "}
              Our VPS plans are optimized to deliver exceptional speed and
              reliability, leveraging advanced hardware and SSD storage to
              ensure fast load times and smooth operations. With dedicated
              resources, your applications will perform consistently, even under
              high traffic conditions.{" "}
            </p>
          </div>
          <div className="bg-brandBlue rounded-md px-6 py-10">
            <img src={flexible} alt="" class="h-5" />
            <p className="leading-snug crimson-font font-bold mb-3 mt-4 w-full lg:w-[70%]">
              {" "}
              Flexibility{" "}
            </p>
            <p className="text-sm leading-6">
              Easily scale your resources as your business grows. Our VPS plans
              allow for seamless upgrades of CPU, RAM, and storage without
              downtime, adapting to your increasing demands. Start small and
              scale up effortlessly to meet your evolving needs.{" "}
            </p>
          </div>
          <div className="bg-brandRed rounded-md px-6 py-10 col-span-1 md:col-span-2 lg:col-span-1">
            <img src={security} alt="" class="h-5" />
            <p className="leading-snug crimson-font font-bold mb-3 mt-4 w-full lg:w-[70%]">
              {" "}
              Security{" "}
            </p>
            <p className="text-sm leading-6">
              {" "}
              We prioritize the security of your data and applications with
              robust measures like firewalls, DDoS protection, and regular
              updates. Automated backups and recovery solutions ensure your data
              is safe and can be restored quickly in case of an emergency.{" "}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          <div className="bg-brandYellow rounded-md px-6 py-10">
            <img src={support} alt="" class="h-5" />
            <p className="leading-snug crimson-font font-bold mb-3 mt-4 w-full lg:w-[70%]">
              {" "}
              Support{" "}
            </p>
            <p className="text-sm leading-6">
              {" "}
              Our dedicated support team is available 24/7 to assist you with
              any issues or questions. From setup and configuration to
              troubleshooting and optimization, our experts provide timely and
              effective support to ensure your VPS runs smoothly at all times.{" "}
            </p>
          </div>
          <div className="bg-brandGreen rounded-md px-6 py-10">
            <img src={value} alt="" class="h-5" />
            <p className="leading-snug crimson-font font-bold mb-3 mt-4 w-full lg:w-[70%]">
              {" "}
              Value{" "}
            </p>
            <p className="text-sm leading-6">
              {" "}
              Enjoy competitive pricing without compromising on quality. Our
              range of VPS plans offers cost-effective solutions tailored to fit
              different needs and budgets, delivering high performance and
              reliability at a great value.{" "}
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default BuyVPS;
