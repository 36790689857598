// import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import LandingPage from "./pages/generalPages/landing";
import SignIn from "./pages/users/auth/signIn";
import AboutUs from "./pages/generalPages/aboutUs";
import CreateAccount from "./pages/users/auth/create-account";
import Layout from "./pages/generalPages/layout";
import UserLayout from "./pages/users/layout";
import { ToastContainer } from "react-toastify";
import Support from "./pages/generalPages/support";
import Contact from "./pages/generalPages/contact";
import Pricing from "./pages/generalPages/servicePricing";
import SendPasswordResetLink from "./pages/users/auth/sendPasswordResetLink";
import ResetPassword from "./pages/users/auth/resetPassword";
import CloudServices from "./pages/generalPages/cloudServices";
function App() {
  return (
    <div className=" bg-[#EEF5FF] dark:bg-[#141D26]">
    <ToastContainer bodyClassName={() => 'toastBody flex items-center text-sm'}/>
    <BrowserRouter>
      {/* <LogoutTimer/> */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/password-reset-link" element={<SendPasswordResetLink />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/cloud-services" element={<CloudServices />} />
          <Route path="/support" element={<Support />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/pricing" element={<Pricing />} />
        </Route>
        <Route path="*" element={<UserLayout/>}/>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
